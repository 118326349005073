import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Post from "../components/post"
import PostSummary from "../components/post-summary"

export default function IndexPage({ data }) {
  const latest = data.latest.edges[0].node
  const posts = data.posts.edges

  return (
    <Layout
      background={data.backdrop.childImageSharp.fluid}
      backgroundColor="040e18"
    >
      <SEO title="Home" />
      <div className="md:m-auto md:w-4/5 mt-6 text-teal-300">
        <div className="md:py-6 md:text-right">
          <span className="opacity-75">Follow Unitystation on:</span>
          <IconLink to="https://discord.gg/tFcTpBp" icon="discord" />
          <IconLink
            to="https://github.com/unitystation/unitystation"
            icon="github"
          />
          <IconLink to="https://www.patreon.com/unitystation" icon="patreon" />
          <IconLink to="https://www.reddit.com/r/unitystation/" icon="reddit" />
        </div>

        <div className="my-8">
          <Post {...latest} contentOnly />
        </div>

        {posts.length > 0 && (
          <div className="md:m-auto md:w-1/2 lead">
            <h2 id="posts" className="md:text-4xl text-center">
              <Link to="/posts">Recent Updates</Link>
            </h2>
            {posts.map(({ node }) => (
              <div key={node.id} className="mb-6">
                <PostSummary {...node} />
              </div>
            ))}
          </div>
        )}

        <Footer>
          <div className="text-teal-300 mt-4">
            <small>Background image by mohammad alizade on Unsplash</small>
          </div>
        </Footer>
      </div>
    </Layout>
  )
}

const IconLink = ({ to, icon }) => (
  <a
    className="icon-link"
    href={to}
    target="_blank"
    rel="noreferrer noopener"
    aria-label={icon}
  >
    <i className={`fab fa-${icon}`} aria-hidden="true" />
  </a>
)

export const query = graphql`
  query homeQuery {
    backdrop: file(relativePath: { regex: "/mohammad-alizade/i" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    latest: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
    ) {
      edges {
        node {
          id
          html
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }

    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
      skip: 1
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
          }
        }
      }
    }
  }
`
