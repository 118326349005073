import React from "react"
import { Link } from "gatsby"

export default function PostSummary({ frontmatter }) {
  return (
    <>
      <h2 className="md:text-3xl text-salmon">
        <Link to={frontmatter.path}>{frontmatter.title}</Link>
      </h2>
      <h3 className="md:text-xl text-salmon opacity-75">{frontmatter.date}</h3>
      <p>{frontmatter.description}</p>
      <div className="text-right">
        <Link to={frontmatter.path}>Keep reading</Link>
      </div>
    </>
  )
}
