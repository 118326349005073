import React from "react"

export default function Footer({ children }) {
  return (
    <footer className="mt-10">
      <div className="hidden md:block clearfix">
        <div className="float-left">
          <div className="footer-badge bg-orange-500 opacity-75">
            Built With
          </div>
          <div className="footer-badge bg-orange-600 font-bold opacity-75">
            Resentment
          </div>
        </div>

        <div className="float-right">
          <div className="footer-badge bg-blue-500 opacity-75">Contains</div>
          <div className="footer-badge bg-blue-600 font-bold opacity-75">
            Technical Debt
          </div>
        </div>
      </div>

      {children}
    </footer>
  )
}
